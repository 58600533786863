import React, { Component } from "react";
import { Col, Row, Container, Jumbotron } from 'reactstrap';
import "./styles.css";

import NCBASummer2024 from '../../img/ncba-summer-2024.webp';

class SummerIntensive extends Component {
    constructor(props) {
        super(props)
        this.state = {
            width: ((window.innerWidth > 576) ? 400 : parseInt(window.innerWidth * .75, 10)),
            height: (parseInt(window.innerHeight * .80, 10)), // 10 is for radix
            mobile: (((window.innerWidth > 576) && (window.innerHeight > 576)) ? false : true)
        }
    }

    render() {
        return (
            <Container className="SummerIntensive" >
                <Row>
                    <Col>
                        <Jumbotron fluid>
                            <Container fluid>
                                <h1 className="display-3">Nashville Classical Ballet Academy<br />Summer Program 2024</h1>
                                <p>Named Outstanding Teachers by Youth America Grand Prix at their first ever Nashville event 2023.</p>
                            </Container>
                        </Jumbotron>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Jumbotron fluid>
                            <Container fluid className="body-content">
                                <Row>
                                    <Col>
                                        <img src={NCBASummer2024} alt="Summer Program 2024" /><br />
                                        <br />
                                        <br />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <h2>SUMMER INTENSIVE 2024<br />(Ages 11-20)</h2>

                                        <h2>DATES</h2>

                                        <table id="intensive-dates-time-table" border="0" cellspacing="0" cellpadding="0">
                                            <tr>
                                                <td style={{paddingBottom: '12px', fontSize:'1.2em'}}>June 3<sup>rd</sup> – June 28<sup>th</sup></td>
                                                <td><br /></td>
                                            </tr>
                                        </table>

                                        <h2>COSTS</h2>

                                        <p>$1,750.00 entire course</p>


                                        <h2>INQUIRING STUDENTS</h2>

                                        <p>To apply please email dance materials to <a href="mailto:info@ncbacademy.com">info@ncbacademy.com</a>, which 
                                            includes a headshot, one or two dance photos, and your student's name and age. We will schedule a class for 
                                            your student to attend and evaluate appropriate placement for the upcoming summer intensive.</p>

                                        <p>
                                            To audition by video please provide the following: 
                                            <ul>
                                                <li>Barre work (5 mins max) </li>
                                                <li>Center work (5mins max) </li>
                                                <li>Pointe work (5 mins max) *if applicable </li>
                                                <li>Including a classical variation and contemporary piece is strongly suggested. </li>
                                                <li>Photographs should include a headshot, tendu a la seconde, and first arabesque</li>
                                            </ul>
                                        </p>


                                        <h2>CLASSES</h2>

                                        <p>Conditioning, Technique, Pointe/Pre-Pointe, Variations, Character, Jazz</p>


                                        <h2>GUEST FACULTY</h2>

                                        <p><strong>Karin Averty</strong><br />
                                            Former Premiere Danseuse, Paris Opera Ballet<br />
                                            Former Principal, San Francisco Ballet<br />
                                            International Guest Teacher<br />
                                            <br />
                                        </p>

                                        <p><strong>Ruben Martin</strong><br />
                                            English National<br />
                                            Former Principal, San Francisco Ballet<br />
                                            Current Principal Teacher, American Ballet Theater<br />
                                            <br />
                                        </p>

                                        <p><strong>Brett Bauer</strong><br />
                                            Former San Francisco Ballet<br />
                                            Former Principal, Oregon Ballet Theater<br />
                                            Certified Gyrotonics Instructor <br />
                                            <br />
                                        </p>

                                        <p><strong>Raissa Chazova Bauer</strong><br />
                                            Master Ballet Academy<br />
                                            Former Novosibirsk Opera and Ballet Theater<br />
                                            Certified Gyrotonics Instructor <br />
                                            <br />
                                        </p>

                                        <p><strong>Melissa Cassidy</strong><br />
                                            Accomplished Dancer and Performer for TV/Film, Broadway and music<br />
                                            <br />
                                        </p>

                                        <p><strong>Katya Klein</strong><br />
                                            Alum of Nashville Classical Ballet<br />
                                            Certified Vaganova Character Teacher<br />
                                            <br />
                                        </p>

                                        <p><strong>Lyrica Woodruff</strong><br />
                                            Graduate of School of American Ballet<br />
                                            Principal Guest Artist<br />
                                            Accomplished Broadway Star<br />
                                            <br />
                                        </p>


                                        <h2>LOCATION</h2>

                                        <p>Legacy Dance Studio<br />
                                        7227 Haley Industrial Drive<br />
                                        Suite 300<br />
                                        Nolensville, TN 37135</p>
                                    </Col>
                                </Row>
                            </Container>
                        </Jumbotron>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Jumbotron fluid>
                            <Container fluid className="body-content">
                                <Row>
                                    <Col>
                                        <h2>SUMMER COURSE 2024<br />
                                            (Ages 8-10)</h2>

                                        <h2>DATES</h2>

                                        <table id="intensive-dates-time-table" border="0" cellspacing="0" cellpadding="0">
                                            <tr>
                                                <td colSpan={2} style={{paddingBottom: '12px', fontSize:'1.2em'}}>June 3<sup>rd</sup> –  July 12<sup>th</sup></td>
                                            </tr>
                                            <tr>
                                                <td>Monday, Wednesday, Friday</td>
                                                <td>1:30pm – 3:00pm</td>
                                            </tr>
                                        </table>

                                        <h2>COSTS</h2>

                                        <p>$675.00 entire course</p>


                                        <h2>INQUIRING STUDENTS</h2>

                                        <p>To apply please email dance materials to <a href="mailto:info@ncbacademy.com">info@ncbacademy.com</a>, which includes 
                                            a headshot, one or two dance photos, and your student's name and age. We will schedule an audition class for your 
                                            student to attend.</p>


                                        <h2>CLASS DESCRIPTION</h2>

                                        <p>Technique class implementing proper progression, musicality, alignment, terminology and coordination.</p>


                                        <h2>ATTIRE</h2>

                                        <p>Black leotard and pink tights for ladies. Proper ballet shoes with single strap elastic. Hair must be pulled back into a neat bun.</p>

                                        <h2>LOCATION</h2>

                                        <p>Legacy Dance Studio<br />
                                            7227 Haley Industrial Drive<br />
                                            Suite 300<br />
                                            Nolensville, TN 37135</p>

                                    </Col>
                                </Row>
                            </Container>
                        </Jumbotron>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default SummerIntensive;
