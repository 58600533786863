import React, { Component } from 'react';
import './styles.css';
import { Container, Row, Col, Jumbotron } from 'reactstrap';

import NutcrackerSuite2024 from '../../img/2024-nutcracker-suite-flyer.webp';
import NCBASummer2024 from '../../img/ncba-summer-2024.webp';
import SpringShowcaseFlyer2024 from '../../img/2024-ncba-spring-showcase-flyer.webp';

class Schedule extends Component {
	render() {
		return(
				<Container className="Schedule">
					<Row>
						<Col>
							<Jumbotron fluid>
								<Container fluid className="body-content">
									<h1 className="display-3">Event Calendar</h1>
								</Container>
							</Jumbotron>
						</Col>
					</Row>
					<Row>
						<Col>
							<Container className="NutcrackerSuiteFlyer">
								<a 
									href="https://prod1.agileticketing.net/websales/pages/info.aspx?evtinfo=458926~24aa882a-d421-4941-ab6b-151179e5aa08&epguid=c31ba8dc-a964-4533-9034-5b78e714baac&">
										<img src={NutcrackerSuite2024} alt="2024 Nutcracker Suite flyer"/>
								</a>
								<br />
								<br />
								<br />
							</Container>
						</Col>
					</Row>
					<Row>
						<Col>
							<Jumbotron fluid>
								<Container fluid className="body-content">
									<h2 className="display-3">Past Events</h2>
								</Container>
							</Jumbotron>
						</Col>
					</Row>
					<Row>
						<Col>
							<Container className="SpringShowcaseFlyer">
								<img src={SpringShowcaseFlyer2024} alt="2024 Spring showcase flyer"/>
								<br />
								<br />
								<br />
							</Container>
						</Col>
					</Row>
					<Row>
						<Col>
							<Container className="summer2024">
								<a href={"/summerintensive"}>
									<img src={NCBASummer2024} alt="Summer Program 2024"/>
								</a>
								<br />
								<br />
								<br />
							</Container>
						</Col>
					</Row>
				</Container>
			);
	}
}

export default Schedule;
