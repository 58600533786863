import React, { Component } from 'react';
import './styles.css';
import { Container, Row, Col, Jumbotron } from 'reactstrap';


import YouTubeFrame from '../YouTubeFrame'
// import IGEmbedFrame from '../IGEmbedFrame'

class Gallery extends Component {
	render() {
		let winX = 768;
		let winY = 468;
		if(window.innerWidth < 1200){
			if(window.innerWidth < 992){
				if(window.innerWidth < 768){
					if(window.innerWidth < 576){
						winX = 258;
						winY = 198;
					}
					else{
						winX = 457;
						winY = 278;
					}
				}
				else{
					winX = 640;
					winY = 390;
				}
			}else{
				winX = 768;
				winY = 468;
			}
		}
	  return(
	    <Container className="Gallery">
				<Row>
					<Col>
						<Jumbotron fluid>
							<Container fluid>
								<h1 className="display-3">Media Gallery</h1>
							</Container>
						</Jumbotron>
					</Col>
				</Row>
				{/* <Jumbotron>
					<Row>
						<Col>
							<IGEmbedFrame token={process.env.REACT_APP_INSTAGRAM_API} squares />
						</Col>
					</Row>
				</Jumbotron> */}
				<Jumbotron>
					<Row>
						<Col>
							<YouTubeFrame src="7Q3uE4afTjU" height={winY} width={winX}/>
						</Col>
						<Col>
							<p>Beautiful work by students of Nashville Classical Ballet Academy</p>
						</Col>
					</Row>
					<hr />
					<Row>
						<Col>
							<YouTubeFrame src="SI15MIQTlMU" height={winY} width={winX}/>
						</Col>
						<Col>
							<p>A little Adagio and NCBA bringing true Classical Ballet training to Nashville</p>
						</Col>
					</Row>
					<hr />
					<Row>
						<Col>
							<YouTubeFrame src="OSJaeM05UWw" height={winY} width={winX}/>
						</Col>
						<Col>
							<p>NCBA's flowers for our winter workshop</p>
						</Col>
					</Row>
					<hr />
					<Row>
						<Col>
							<YouTubeFrame src="E_ysjBgLfNg" height={winY} width={winX}/>
						</Col>
						<Col>
							<p>NCBA's Contemporaries for a showcase at the Jameson Theater are going to be amazing!</p>
						</Col>
					</Row>
					<hr />
					<Row>
						<Col>
							<YouTubeFrame src="L_eFpERwzD4" height={winY} width={winX}/>
						</Col>
						<Col>
							<p>NCBA's rehearsals for La Bayadere for our showcase at the Jamison Theater in Franklin, TN</p>
						</Col>
					</Row>
					<hr />
					<Row>
						<Col>
							<YouTubeFrame src="2UzNTQ-G83c" height={winY} width={winX}/>
						</Col>
						<Col>
							<p>Nissa Wood</p>
							<p>Accepted into the </p>
							<p>Royal Ballet School Summer Program</p>
						</Col>
					</Row>
					<hr />
					<Row>
						<Col>
							<YouTubeFrame src="RZNlRsg6sL8" height={winY} width={winX}/>
						</Col>
						<Col>
							<p>Karen Humphrey</p>
							<p>Teatrul De Balet Sibiu</p>
							<p>Coached by Aaron Orza</p>
						</Col>
					</Row>
					<hr />
					<Row>
						<Col>
							<YouTubeFrame src="4Mepg8po39g" height={winY} width={winX}/>
						</Col>
						<Col>
							<p>Ayami Sato</p>
							<p>Arts Ballet Theatre of Florida</p>
							<p>Coached by Aaron Orza</p>
						</Col>
					</Row>
					<hr />
					<Row>
						<Col>
							<YouTubeFrame src="L5rG9SiFxAU" height={winY} width={winX}/>
						</Col>
						<Col>
							<p>Nicole Ostertag</p>
							<p>Choreographed by Aaron Orza</p>
						</Col>
					</Row>
					<hr />
					<Row>
						<Col>
							<YouTubeFrame src="hKRMZT781lA" height={winY} width={winX}/>
						</Col>
						<Col>
							<p>Harry Warshaw</p>
							<p>Pittsburgh Ballet Theatre</p>
							<p>Coached by Aaron Orza</p>
						</Col>
					</Row>
					<hr />
					<Row>
						<Col>
							<YouTubeFrame src="G33G-SG6Ro4" height={winY} width={winX}/>
						</Col>
						<Col>
							<p>Rocio Aguero</p>
							<p>Ballet Estable del Teator Colon</p>
							<p>Coached by Aaron Orza</p>
						</Col>
					</Row>
					<hr />
					<Row>
						<Col>
							<YouTubeFrame src="Sraj3VsihA8" height={winY} width={winX}/>
						</Col>
						<Col>
							<p>Kenna Gold</p>
							<p>Orlando Ballet Trainee</p>
							<p>Choreographed by Aaron Orza</p>
						</Col>
					</Row>
					<hr />
					<Row>
						<Col>
							<YouTubeFrame src="ax-I1ZPNTvI" height={winY} width={winX}/>
						</Col>
						<Col>
							<p>Anna Tateda</p>
							<p>Victoria, BC</p>
							<p>Choreographed by Aaron Orza</p>
						</Col>
					</Row>
					<hr />
					<Row>
						<Col>
							<YouTubeFrame src="VwR94b-gUuk" height={winY} width={winX}/>
						</Col>
						<Col>
							<p>Abigail Larkin</p>
							<p>YAGP Virtual Audition</p>
							<p>Choreographed by Aaron Orza</p>
						</Col>
					</Row>
					<hr />
					<Row>
						<Col>
							<YouTubeFrame src="O5W6Yh-IJHA" height={winY} width={winX}/>
						</Col>
						<Col>
							<p>Nissa Wood</p>
							<p>Studio Rehearsal of Nikiya</p>
						</Col>
					</Row>
					<hr />
					<Row>
						<Col>
							<YouTubeFrame src="A8LUJEHu4Fo" height={winY} width={winX}/>
						</Col>
						<Col>
							<p>Sophia Rothenberg</p>
							<p>Esmeralda</p>
						</Col>
					</Row>
					<hr />
					<Row>
						<Col>
							<YouTubeFrame src="gFMFlFDubQU" height={winY} width={winX}/>
						</Col>
						<Col>
							<p>Abby Larkin</p>
							<p>Jazz Piece</p>
							<p>Choreographed by Kristin Lindsay</p>
						</Col>
					</Row>
					<hr />
					<Row>
						<Col>
							<YouTubeFrame src="R2Xz6FIl62g" height={winY} width={winX}/>
						</Col>
						<Col>
							<p>Juliana Bellissimo</p>
							<p>Paquita</p>
						</Col>
					</Row>
					<hr />
					<Row>
						<Col>
							<YouTubeFrame src="R1Q7YAXMzRM" height={winY} width={winX}/>
						</Col>
						<Col>
							<p>Abigail Dorn</p>
							<p>Paquita</p>
						</Col>
					</Row>
					<hr />
					<Row>
						<Col>
							<YouTubeFrame src="ZUrsvkJgfzU" height={winY} width={winX}/>
						</Col>
						<Col>
							<p>Brooke Wilson</p>
							<p>Don Q</p>
						</Col>
					</Row>
					<hr />
					<Row>
						<Col>
							<YouTubeFrame src="DYoz6eyB3e8" height={winY} width={winX}/>
						</Col>
						<Col>
							<p>Abby Larkin</p>
							<p>Flames of Paris</p>
						</Col>
					</Row>
					<hr />
					<Row>
						<Col>
							<YouTubeFrame src="mCAcVBl7iso" height={winY} width={winX}/>
						</Col>
						<Col>
							<p>Katya Klein</p>
							<p>Gamzatti</p>
						</Col>
					</Row>
					<hr />
					<Row>
						<Col>
							<YouTubeFrame src="V1Wyp8hpfIQ" height={winY} width={winX}/>
						</Col>
						<Col>
							<p>Hannah Hewitt</p>
							<p>When I Am Laid In Earth</p>
						</Col>
					</Row>
					<hr />
					<Row>
						<Col>
							<YouTubeFrame src="zFh7yel_vA0" height={winY} width={winX}/>
						</Col>
						<Col>
							<p>2020 Character Showcase</p>
						</Col>
					</Row>
					<hr />
					<Row>
						<Col>
							<YouTubeFrame src="3_60ZDqluVY" height={winY} width={winX}/>
						</Col>
						<Col>
							<p>Class Work 2020</p>
						</Col>
					</Row>
				</Jumbotron>

			</Container>
	  );
	}
}

export default Gallery;
