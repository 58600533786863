import React, { Component } from 'react';
import './styles.css';
import { Container, Row, Col, Jumbotron } from 'reactstrap';


class LatestVideo extends Component {
    render() {
        return(
            <Container className="Gallery">
                <Row>
                    <Col>
                        <Jumbotron fluid>
                            <Container fluid>
                                <h1 className="display-3">Media Gallery</h1>
                            </Container>
                        </Jumbotron>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Jumbotron fluid>
                            <Container fluid>
                            </Container>
                        </Jumbotron>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default LatestVideo;
