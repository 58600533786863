import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import './styles.css';

import Logo from '../../img/logo.gif';


class Footer extends Component {
    render() {
        return (
            <div className="Footer">
                <div className="container">
                    <Row>
                        <Col sm="6" className="addressBox">
                            <img id="footerLogo" src={Logo} alt="Nashville Classical Ballet Academy"/>
                            <p>7227 Haley Industrial Drive</p>
                            <p>Suite 300</p>
                            <p>Nolensville, TN 37135</p>
                            <p><a href="mailto:info@ncbacademy.com">info@ncbacademy.com</a></p>
                            <p>(415)-613-4104</p>
                            <a href="https://www.facebook.com/ncbacademy/"><i className="mediaIcon fab fa-facebook"></i></a>
                            <a href="https://www.instagram.com/ncbacademy/"><i
                                className="mediaIcon fab fa-instagram"></i></a>
                            <a href="https://www.youtube.com/channel/UCJ66_WrcxEgfxtH3kIgCVEQ"><i
                                className="mediaIcon fab fa-youtube"></i></a>
                            <form className="paypal-donation" action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                                <input type="hidden" name="cmd" value="_s-xclick"/>
                                <input type="hidden" name="hosted_button_id" value="EABJ3TGHV4PHA"/>
                                <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_SM.gif"
                                       border="0" name="submit" title="PayPal - The safer, easier way to pay online!"
                                       alt="Donate with PayPal button"/>
                                <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1"
                                     height="1"/>
                            </form>
                        </Col>
                        <Col sm="6" className="mapBox">
                            <iframe
                                title="Map to 7227 Haley Industrial Drive"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3229.0035482232784!2d-86.67588162361638!3d35.971338713982064!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88647144f34aaa97%3A0xc57b86a68c75192d!2sGovenors%20Commons%20Corporate%20Complex%2C%207227%20Haley%20Industrial%20Dr%20STE%20300%2C%20Nolensville%2C%20TN%2037135!5e0!3m2!1sen!2sus!4v1709002828410!5m2!1sen!2sus"
                                width="100%" height="300" frameborder="0" allowfullscreen="" aria-hidden="false"
                                tabindex="0"></iframe>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

export default Footer;
